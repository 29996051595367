import React from 'react';
import { Link } from 'gatsby';

import Layout from '../components/layout';
import Grid from '../components/grid/grid';
import GridItem from '../components/grid/item';

const ThankYouPage = () => (
  <Layout title="Thank You">
    <section className="thank-you-page">
      <Grid>
        <GridItem>
          <h2>Thank You!</h2>
          <p>We will be in touch with you shortly.</p>
          <Link to="/" className="c-button--primary">
            Go back to the homepage
          </Link>
        </GridItem>
      </Grid>
    </section>
  </Layout>
);

export default ThankYouPage;
